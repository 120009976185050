import { NgModule } from '@angular/core';

import { MenuItems } from './menu-items/menu-items';
import { AccordionAnchorDirective, AccordionLinkDirective, AccordionDirective } from './accordion';
import { ServiceComponent } from './service/service.component';
import { NotificationServiceComponent } from './notification-service/notification-service.component';
import {CommonModule} from '@angular/common';
import { AuthService } from './authservice/authservice.component';
import { AuthGuard } from './authgurd/authgurd.component';
import { JwtHelper } from 'angular2-jwt';
import { VideoProcessingService } from './video-processing-service';

//import { ToBeSharedModule } from '../shared/MainPipe.module';


@NgModule({
  imports: [
   //FormsModule,CommonModule
   //ToBeSharedModule
  ],
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective
   // ,ToBeSharedModule
   
   // SsoComponent
   

  ],
  exports: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
   ],
  providers: [ MenuItems, VideoProcessingService, ServiceComponent, NotificationServiceComponent, AuthService, AuthGuard,JwtHelper]
})
export class SharedModule { }
