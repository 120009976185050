import { Component } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class AppHeaderComponent {
  constructor(private router:Router ){}
  logout() : void {
    
    
      localStorage.setItem("IsLogin","false");
      localStorage.removeItem("IsLogin");
                        sessionStorage.removeItem('id_token');
                        localStorage.removeItem('id_token');
                        //localStorage.removeItem('username');
                        localStorage.removeItem('email');
                        localStorage.removeItem('Id');
                        localStorage.removeItem('role');
     this.router.navigate(["login"]);
   
  }

}
