
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material';

import { Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';
@Injectable()
export class NotificationServiceComponent {
    private subject = new Subject<any>();
    constructor(private snackBar: MatSnackBar) {
    }
    setAutoHide: boolean = true;
    autoHide: number = 7000;
    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'bottom';

    addExtraClass: boolean = false;
    showFailure(msg: string) {
        let config = new MatSnackBarConfig();
        config.verticalPosition = this.verticalPosition;
        config.horizontalPosition = this.horizontalPosition;
        config.duration = this.setAutoHide ? this.autoHide : 0;
        config.panelClass = ['custom-class'];
        this.snackBar.open(msg, null, config);
    }
    showSuccess(msg: string) {
        let config = new MatSnackBarConfig();
        config.verticalPosition = this.verticalPosition;
        config.horizontalPosition = this.horizontalPosition;
        config.duration = this.setAutoHide ? this.autoHide : 0;
        config.panelClass = ['custom-class1'];
        this.snackBar.open(msg, null, config);
    }
    confirmThis(message: string, siFn: () => void, noFn: () => void) {
        this.setConfirmation(message, siFn, noFn);
    }
    setConfirmation(message: string, siFn: () => void, noFn: () => void) {
        let that = this;
        this.subject.next({
            type: "confirm",
            text: message,
            siFn:
                function () {
                    that.subject.next(); //this will close the modal
                    siFn();
                },
            noFn: function () {
                that.subject.next();
                noFn();
            }
        });

    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
}