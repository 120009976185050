import { Routes,RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { FullComponent } from './layouts/full/full.component';


import { SsoComponent } from './shared/sso/sso.component';

import { AuthGuard } from './shared/authgurd/authgurd.component';
import { PageNotFoundComponent } from './shared/page-notfound/page-notfound.component';

export const AppRoutes: Routes = [
  {
  path: '',
  component: FullComponent,
  
  
  children: [{ 
    path: '', 
    redirectTo: '/login', 
    pathMatch: 'full' 
  }, {
    path: '',
    loadChildren: './material-component/material.module#MaterialComponentsModule'
  }, {
    path: 'dashboard',
    loadChildren: './starter/starter.module#StarterModule'
  },{
    path: 'login',
    loadChildren: './login/login.module#LoginModule'
  },{
    path: 'user-register',
    loadChildren: './user-register/user-register.module#UserRegisterModule'
  },{
    path: 'upload',
    loadChildren: './file-upload/file-upload.module#FileUploadModule'
  },{
    path: 'assign-video',
    loadChildren: './assign-video/assign-video.module#AssignVideoModule'
  }
  ,{
    path: 'video',
  loadChildren: './uservideo/uservideo.module#UservideoModule'
 },{
  path: 'usergrid',
loadChildren: './usergrid/usergrid.module#UsergridModule'
}
,{
  path: 'videogrid',
loadChildren: './videogrid/videogrid.module#VideogridModule'
}
,{
path: 'user-rigister-grid',
loadChildren: './register-grid/register-grid.module#RegisterGridModule'
}
,{
  path: 'sso/:id-user',
component: SsoComponent
  }
  ,{
    path: '**',
  component: PageNotFoundComponent
    }
]

} ];

export const routing: ModuleWithProviders =
    RouterModule.forRoot(AppRoutes,{useHash:true});
