import { Component, OnInit, Input,AfterContentInit } from '@angular/core';
import {Router,ActivatedRoute} from '@angular/router';
import {FormControl, FormGroupDirective, NgForm, Validators,FormGroup,FormBuilder} from '@angular/forms';
import { FullComponent } from '../../layouts/full/full.component';
import { ServiceComponent } from '../../shared/service/service.component';
import { NotificationServiceComponent } from '../notification-service/notification-service.component';
import { isDebuggerStatement } from 'typescript';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-sso',
  templateUrl: './sso.component.html',
  styleUrls: ['./sso.component.css'],
  inputs:["routemodel"]
})
export class SsoComponent implements OnInit,AfterContentInit {
  IsLogin:string;
  newobject:any;
  @Input() routemodel:any
  user:any;
  email:any;
  constructor(private router: Router,  private route: ActivatedRoute,private _userService: ServiceComponent,private NotificationService:NotificationServiceComponent,private spinnerService: Ng4LoadingSpinnerService) {
    
    //this.isValid=false
    localStorage.removeItem("IsLogin");
    sessionStorage.removeItem('id_token');
    localStorage.removeItem('id_token');
    //localStorage.removeItem('username');
    localStorage.removeItem('email');
    localStorage.removeItem('Id');
    localStorage.removeItem('role');
    localStorage.setItem("IsLogin","false");
 this.IsLogin = localStorage.getItem('IsLogin');

   }
  
  ngOnInit() {
    debugger
 if(this.IsLogin==="false")
 {
  this.newobject=this.route.snapshot.params;
  if(this.newobject!=undefined&&this.newobject!=null){
 
    localStorage.setItem("user",this.newobject["id-user"]);
  
  }
  
 }

   
  }
ngAfterContentInit(){
  debugger
  this.spinnerService.show();

    this.user = localStorage.getItem('user');

    if(this.user!=null&&this.user!=undefined){
  debugger
    this._userService.post(this._userService.BaseUrl+"/api/user/sso?username="+this.user,null).subscribe(
  
          data => {
            this.spinnerService.hide()
              //if (data == 1) //Success
              //{
             
                  if (data.error == false) {
                  
                      //this.NotificationService.showSuccess("valid");
                      localStorage.removeItem('id_token');
            
                      localStorage.setItem("IsLogin","true");
                      sessionStorage.setItem('id_token', data.token);
                          localStorage.setItem('id_token', data.token);
                          localStorage.setItem('username', data.UserName);
                          localStorage.setItem('email', data.Email);
                          localStorage.setItem('Id', data.Id);
                          localStorage.setItem('role', data.role);
  //localStorage.setItem('email', data.Email);
   var Name=localStorage.getItem("username");
  var boolean=(localStorage.getItem("role")==="Admin" ? true:false)
  if(boolean){
      this.router.navigate(["dashboard"]);
      return false;
  }
  else{
      this.router.navigate(["video"]);
      return false;
  }
  
                      
                      // sessionStorage.setItem('id_token', data.token);
                      // localStorage.setItem('id_token', data.token);
                      // localStorage.setItem('username', data.UserName);
                      // localStorage.setItem('email', data.Email);
  
  
  
                      // debugger
                      // this.router.navigate(['/home']);
                  }
                  else {
  debugger
                      this.NotificationService.showFailure(data.message);
               
                //this.router.navigate(["login"]);
                return false;
                  }
                  
  
              
  
  
              //}
              //else {
              //    this.msg = "There is some issue in saving records, please contact to system administrator!"
              //}
  
  
          },
          error => {this.spinnerService.hide()
             this.NotificationService.showFailure("error found,Please try again");
             window.close();
          }
      );
    }

}
}
