import { Component, AfterViewInit, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { FormsModule, FormControl, FormBuilder, NgModel, FormGroup, Validators } from '@angular/forms';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { FullComponent } from '../layouts/full/full.component';
import { ServiceComponent } from '../shared/service/service.component';
import { NotificationServiceComponent } from '../shared/notification-service/notification-service.component';
import { Inject } from "@angular/core";
import { DOCUMENT } from '@angular/platform-browser'
import { Compiler } from '@angular/core';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'assign-video-dialog',
  templateUrl: './assign-video-dialog.component.html',
})
export class AssignVideoDialog {

  constructor(private _userService: ServiceComponent,
    public dialogRef: MatDialogRef<AssignVideoDialog>,
    private NotificationService: NotificationServiceComponent,
    @Inject(MAT_DIALOG_DATA) public data: any
    //@Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  user: string;

  AssignVideoToSelectedUsers(vId: any, video: any) {
    debugger;

    if (this.user === undefined) {
      this.NotificationService.showFailure("User Required");
      return false
    }
    this._userService.get(this._userService.BaseUrl + "/api/user/save_assign_video?userid=" + this.user + "&assignlist=" + vId + "&Subject=" + "" + "&MCategory=" + "").subscribe(
      data => {
        debugger
        var rec_data = data.data;
        console.log(rec_data);
        this.NotificationService.showSuccess(data.message);
        this.dialogRef.close();
      }, error => {
        this.NotificationService.showFailure(error);
      }
    );
  }

  @ViewChild('userDDL') usersSelect;
  allUsersSelected = false;
  SelectAllUsers() {
    if (!this.allUsersSelected) {
      this.usersSelect.options.forEach(k => k.select());
      this.allUsersSelected = true;
    }
    else {
      this.usersSelect.options.forEach(k => k.deselect());
      this.allUsersSelected = false;
    }
  }


  FilterId: any;
  Filterdata: any = [{ id: 0, name: 'All' }, { id: 1, name: 'Today' }, { id: 2, name: '<= Week' }, { id: 3, name: '<= 15 Days' }, { id: 4, name: '<= Month' }
    , { id: 5, name: '<= 3 Month' }, { id: 6, name: '<= 6 Month' }, { id: 7, name: '<= 1 Year' }

  ];
  userdata: any[];
  getusers(val: any) {
    debugger
    if (val != undefined) {
      var displayDate = new Date().toLocaleDateString();
      var currentUserId = localStorage.getItem("Id");
      var currentUserRole = localStorage.getItem("role");
      this._userService.get(this._userService.BaseUrl + "/api/user/Userfilterlist?searchId=" + val + "&currentDate=" + displayDate + "&currentUserId=" + currentUserId + "&currentUserRole=" + currentUserRole).subscribe(
        data => {
          debugger
          this.userdata = data.data
        }, error => {
          this.NotificationService.showFailure(error);
        });
    }
  }
}