import {Component, Input, OnDestroy, Inject, ViewEncapsulation,OnInit} from '@angular/core';
import {Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError} from '@angular/router';
import {DOCUMENT} from '@angular/common';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
@Component({
    selector: 'app-spinner',
    template: `<ng4-loading-spinner> </ng4-loading-spinner>`,
    encapsulation: ViewEncapsulation.None
})
// <div class="preloader" *ngIf="isSpinnerVisible">
//         <div class="spinner">
//           <div class="double-bounce1"></div>
//           <div class="double-bounce2"></div>
//         </div>
//     </div>
export class SpinnerComponent implements OnInit, OnDestroy {
public isSpinnerVisible = true;
@Input() public backgroundColor = 'rgba(0, 115, 170, 0.69)';
ngOnInit():void{
    
}

    constructor(private router: Router, @Inject(DOCUMENT) private document: Document,private spinnerService: Ng4LoadingSpinnerService) {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.isSpinnerVisible = true;
                this.spinnerService.show();
            } else if ( event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
                this.isSpinnerVisible = false;
                this.spinnerService.hide()
            }
        }, () => {
            this.isSpinnerVisible = false;
            this.spinnerService.hide()
        });
    }

    ngOnDestroy(): void {
        this.isSpinnerVisible = false;
        this.spinnerService.hide()
    }
}
