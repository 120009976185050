import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { tokenNotExpired, JwtHelper } from 'angular2-jwt';

@Injectable()
export class AuthService {
    
    constructor(private jwtHelper: JwtHelper) {
    }
 
    //private jwtHelper: JwtHelper = new JwtHelper();
    private token = localStorage.getItem('id_token');

    isExpired() {
        return this.jwtHelper.isTokenExpired(this.token);
    }

}