import { ChangeDetectorRef, Component, NgZone, OnDestroy, ViewChild, HostListener, Directive, AfterViewInit } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { MenuItems } from '../../../shared/menu-items/menu-items';

import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: []
})
export class AppSidebarComponent {
  mobileQuery: MediaQueryList;
  Name: string = localStorage.getItem("username");
  role: boolean = (localStorage.getItem("role") === "Admin" ? true : false)

  private _mobileQueryListener: () => void;
  check(data): void {
    debugger

  }

  constructor(private router: Router,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems) {

    debugger
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  logout(): void {

    localStorage.setItem("IsLogin", "false");
    localStorage.removeItem("IsLogin");
    sessionStorage.removeItem('id_token');
    localStorage.removeItem('id_token');
    //localStorage.removeItem('username');
    localStorage.removeItem('email');
    localStorage.removeItem('Id');
    localStorage.removeItem('role');
    this.router.navigate(["login"]);
    location.reload();
  }
  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
}