import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { tokenNotExpired, JwtHelper } from 'angular2-jwt';
//import { AuthService } from '../Service/AuthService';


@Injectable()
export class AuthGuard implements CanActivate {
    token1: string
    constructor(private router: Router, private JwtHelper: JwtHelper) {
        this.token1 = localStorage.getItem('id_token');
    };

    private token = localStorage.getItem('id_token');
   
    
    isExpired() {
      
        var bool = this.JwtHelper.isTokenExpired(this.token);
        
        localStorage.setItem('decodeToken', this.JwtHelper.decodeToken(this.token));
        
        return this.JwtHelper.isTokenExpired(this.token);
    }
    
    canActivate() {
        
        if (this.token1 == null)
        {
            localStorage.removeItem('id_token');
            this.router.navigate(['/login']);
            location.reload();
            return true;
        }
        // Check to see if a user has a valid JWT
       else if (this.isExpired()) { 
          
            localStorage.removeItem('id_token');
            this.router.navigate(['/login']);
         //location.reload();
            // If they do, return true and allow the user to load the home component
            return true;
        }
     
        //this.router.navigate(['/home']);
        return true;
        // If not, they redirect them to the login tokenNotExpired
    }
}