import { Injectable, Inject, ViewContainerRef,Input } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Observable';
// import { Router } from '@angular/router';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

// import { ToastsManager } from 'ng2-toastr/ng2-toastr';
// import { NotificationService } from './NotificationService';
// import {  Globaldata } from '../Model/User';
@Injectable()
export class ServiceComponent {
   // @Input() GlobalModel: Globaldata
// BaseUrl = 'http://videoapi.alphaedu.info/';
//BaseUrl: string = 'http://localhost:1312/';
BaseUrl = 'http://api.ealphaclips.com/';


// MainUrl: string = 'http://localhost:1312';
MainUrl:string='http://ealphaclips.com';
    // BaseGlobalUrl: string = 'http://localhost:7684/GetGlobalData?Username=';
    constructor(private _http: Http) {  }
    // myAppUrl: string = "";
    // constructor(private _http: Http, @Inject('BASE_URL') baseUrl: string) {
    //    debugger
    //    this.myAppUrl = baseUrl;
    // }  
    // GlobalData(): void {
    //     debugger
    //     this.get(this.BaseUrl + "/api/Admin/GetGlobalData?Username=" + localStorage.getItem("username")).subscribe(
    //         data => {
    //             debugger
    //             this.GlobalModel = data.data
    //         }, error => {
    //             this.NotificationService.showFailure(error);
    //             //this.msg = error;
    //         });
    // }

    // getGlobal(): Observable<any> {
    //    debugger
    //    return this.get(this.BaseUrl + "/api/Admin/GetGlobalData?Username=" + localStorage.getItem("username"))
    //        .map((response: Response) => <any>response.json())
    //        .catch(this.handleError);


    //}


    get(url: string): Observable<any> {
        
        return this._http.get(url)
            .map((response: Response) => <any>response.json() )
            .catch(this.handleError);
           

    }

    post(url: string, model: any): Observable<any> {
     
        const body = JSON.stringify(model);
        const headers = new Headers({ 'Content-Type': 'application/json' });
       // headers.append('Access-Control-Allow-Origin', '*');
       const options = new RequestOptions({ headers: headers });
      
        return this._http.post(url, body, options)
            .map((response: Response) => <any>response.json())
        
            .catch(this.handleError); 
        
    }

    put(url: string, id: number, model: any): Observable<any> {
        const body = JSON.stringify(model);
        const headers = new Headers({ 'Content-Type': 'application/json' });
        const options = new RequestOptions({ headers: headers });
        return this._http.put(url + id, body, options)
            .map((response: Response) => <any>response.json())
            .catch(this.handleError);
    }

    delete(url: string, id: number): Observable<any> {
        const headers = new Headers({ 'Content-Type': 'application/json' });
        const options = new RequestOptions({ headers: headers });
        return this._http.delete(url + id, options)
            .map((response: Response) => <any>response.json())
            .catch(this.handleError);
    }
    postFile(url: string,caption: string, fileToUpload: File): Observable<any> {
        const headers = new Headers();
      //  headers.append('Access-Control-Allow-Origin', '*');
    
        //headers.append('Authorization', 'bearer ' + token);
    
       
        //headers.append('Content-Type', 'undefined');
    
       // let headers = new Headers({ 'Content-Type': 'application/json' });
        //let headers = new Headers({ 'Content-Type': 'application/json' });
        const options = new RequestOptions({ headers: headers });
       // const formData: FormData = new FormData();
        //formData.append('fileKey', fileToUpload, fileToUpload.name);
        const formData: FormData = new FormData();
        formData.append('Image', fileToUpload);
        formData.append('ImageCaption', caption);
          
        return this._http
            .post(url, formData , options)
            .map((response: Response) => <any>response.json())
            .catch(this.handleError);
    }
    private handleError(error: Response) {
        
        //if (error.statusText != null)
        //{
        //    this.NotificationService.showFailure(error.statusText)
        //    return Observable.empty();
        //}
        //else {
        //    this.NotificationService.showFailure(error.json().error || 'Server error')
            
        return Observable.throw(error.statusText || 'Server error');
       // }
        
    }
    // makeFileRequest(url: string, params: string[], files: File[]): Observable<any> {debugger
    //     return Observable.create(observer => {debugger
    //         let formData: FormData = new FormData(),
    //             xhr: XMLHttpRequest = new XMLHttpRequest();
    
    //         for (let i = 0; i < files.length; i++) {
    //             formData.append("uploads[]", files[i], files[i].name);
    //         }
    
    //         xhr.onreadystatechange = () => {
    //             if (xhr.readyState === 4) {
    //                 if (xhr.status === 200) {
    //                     observer.next(JSON.parse(xhr.response));
    //                     observer.complete();
    //                 } else {
    //                     observer.error(xhr.response);
    //                 }
    //             }
    //         };
    
    //         xhr.upload.onprogress = (event) => {
    //             this.progress = Math.round(event.loaded / event.total * 100);
    
    //             this.progressObserver.next(this.progress);
    //         };
    
    //         xhr.open('POST', url, true);
    //         var serverFileName = xhr.send(formData);
    //         return serverFileName;
    //     });
    // }
    

   // public manageschoolaccesscode = './app/Component/School/manageschoolaccesscode.html';

}
 







