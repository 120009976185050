import * as $ from 'jquery';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { LocationStrategy, HashLocationStrategy, CommonModule } from '@angular/common';
import { routing } from './app.routing';
import { AppComponent } from './app.component';

import { FlexLayoutModule } from '@angular/flex-layout';
import { FullComponent } from './layouts/full/full.component';
import { AppHeaderComponent } from './layouts/full/header/header.component';
import { AppSidebarComponent } from './layouts/full/sidebar/sidebar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DemoMaterialModule } from './demo-material-module';
//import {HttpModule} from '@angular/http';
import { SharedModule } from './shared/shared.module';
import { SpinnerComponent } from './shared/spinner.component';
// import { LoginModule } from './Register/login/login.module';
//import { FileUploadModule } from './file-upload/file-upload.module';
//import { RegisterModule } from './Register/Register/register.module';
import { ServiceComponent } from './shared/service/service.component';
import { NotificationServiceComponent } from './shared/notification-service/notification-service.component';
import { AuthGuard } from './shared/authgurd/authgurd.component';
import { PageNotFoundComponent } from './shared/page-notfound/page-notfound.component';
//import { EditDialogComponent } from './shared/edit-dialog-component/edit-dialog-component.component';
import { EditDialogComponent } from './edit-dialog/edit-dialog.component';


import { AssignVideoDialog } from './AssignVideoDialog/assign-video-dialog.component';

//import {GroupByPipe} from '../app/groupBy.pipe';

import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { SsoComponent } from './shared/sso/sso.component';

//import { UservideoComponent } from './uservideo/uservideo.component';
//import { VideosComponent } from './videos/videos.component';
//import {DialogDataExampleDialog} from './uservideo/uservideo.component';

import { DialogconfmComponent } from './shared/dialogconfm/dialogconfm.component';

import { ExcelService } from './ExcelService.service';

//import { ClickOutsideDirective } from './highlight/highlight.component';


@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    AppHeaderComponent,
    SpinnerComponent,
    AppSidebarComponent,
    PageNotFoundComponent, SsoComponent
    //EditDialogComponent
    //,GroupByPipe
    //UservideoComponent,
    //VideosComponent,
    , AssignVideoDialog
    //,ClickOutsideDirective

  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    DemoMaterialModule,
    FormsModule,
    FlexLayoutModule,
    HttpClientModule,
    HttpModule,
    SharedModule,
    //,GroupByPipe,
    routing, Ng4LoadingSpinnerModule.forRoot()
  ],
  entryComponents: [AssignVideoDialog],
  exports: [RouterModule],

  providers: [
    // {
    //   provide: LocationStrategy,
    //   useClass: HashLocationStrategy
    // },
    ServiceComponent, NotificationServiceComponent, AuthGuard, SsoComponent,
    ExcelService
  ],
  bootstrap: [AppComponent], schemas: [CUSTOM_ELEMENTS_SCHEMA]
  // entryComponents:[EditDialogComponent]

})
export class AppModule { }
