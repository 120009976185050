import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class VideoProcessingService {

  constructor(
    @Inject(DOCUMENT) private document: Document
  ) { }

  public promptForVideo(): Promise<File> {
    return new Promise<File>((resolve, reject) => {
      // make file input element in memory
      const fileInput: HTMLInputElement = this.document.createElement('input');
      fileInput.type = 'file';
      fileInput.accept = 'video/*';
      fileInput.setAttribute('capture', 'camera');
      // fileInput['capture'] = 'camera';
      fileInput.addEventListener('error', event => {
        reject(event.error);
      });
      fileInput.addEventListener('change', event => {
        resolve(fileInput.files[0]);
      });
      // prompt for video file
      fileInput.click();
    });
  }

  public generateThumbnail(videoFile: Blob): Promise<string> {
    debugger
    const video = this.document.createElement('video');
    const canvas: HTMLCanvasElement = this.document.createElement('canvas');
    const context: CanvasRenderingContext2D = canvas.getContext('2d');
    return new Promise<string>((resolve, reject) => {
     // canvas.addEventListener('error',  reject);
      //video.addEventListener('error',  reject);
      video.addEventListener('canplay', event => {
        debugger
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        resolve(canvas.toDataURL());
      });
      if (videoFile[0].type) {
        video.setAttribute('type', videoFile[0].type);
      }
      video.preload = 'auto';
      video.src = window.URL.createObjectURL(videoFile[0]);
      debugger

video.load();
//video.pause()
video.currentTime=5;

//             var i = 0;

// video.addEventListener('loadeddata', function() {
//     this.currentTime = i;
// });
    //   var i = setInterval(function() {
    //     if(video.readyState > 0) {
    //       //var minutes = parseInt((video.duration/ 60), 10);
    //       var seconds = video.duration % 60;
      
    //       // (Put the minutes and seconds in the display)
      
    //       clearInterval(i);
    //       video.load();
    //     }
    //   }, 5000);
     

     });
  }
  public generateThumbnail2(videoFile: Blob): Promise<string> {
    debugger
    const video = this.document.createElement('video');
    const canvas: HTMLCanvasElement = this.document.createElement('canvas');
    const context: CanvasRenderingContext2D = canvas.getContext('2d');
    return new Promise<string>((resolve, reject) => {
      canvas.addEventListener('error',  reject);
      video.addEventListener('error',  reject);
      video.addEventListener('canplay', event => {
        debugger
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        resolve(canvas.toDataURL());
      });
      if (videoFile[0].type) {
        video.setAttribute('type', videoFile[0].type);
      }
      video.preload = 'auto';
      video.src = window.URL.createObjectURL(videoFile[0]);
      debugger

video.load();
//video.pause()
video.currentTime=10;

//             var i = 0;

// video.addEventListener('loadeddata', function() {
//     this.currentTime = i;
// });
    //   var i = setInterval(function() {
    //     if(video.readyState > 0) {
    //       //var minutes = parseInt((video.duration/ 60), 10);
    //       var seconds = video.duration % 60;
      
    //       // (Put the minutes and seconds in the display)
      
    //       clearInterval(i);
    //       video.load();
    //     }
    //   }, 5000);
     

     });
  }
  public generateThumbnail3(videoFile: Blob): Promise<string> {
    debugger
    const video = this.document.createElement('video');
    const canvas: HTMLCanvasElement = this.document.createElement('canvas');
    const context: CanvasRenderingContext2D = canvas.getContext('2d');
    return new Promise<string>((resolve, reject) => {
      canvas.addEventListener('error',  reject);
      video.addEventListener('error',  reject);
      video.addEventListener('canplay', event => {
        debugger
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        resolve(canvas.toDataURL());
      });
      if (videoFile[0].type) {
        video.setAttribute('type', videoFile[0].type);
      }
      video.preload = 'auto';
      video.src = window.URL.createObjectURL(videoFile[0]);
      debugger

video.load();
//video.pause()
video.currentTime=15;

//             var i = 0;

// video.addEventListener('loadeddata', function() {
//     this.currentTime = i;
// });
    //   var i = setInterval(function() {
    //     if(video.readyState > 0) {
    //       //var minutes = parseInt((video.duration/ 60), 10);
    //       var seconds = video.duration % 60;
      
    //       // (Put the minutes and seconds in the display)
      
    //       clearInterval(i);
    //       video.load();
    //     }
    //   }, 5000);
     

     });
  }

}



