import * as $ from 'jquery';
import { MediaMatcher } from '@angular/cdk/layout';

import { ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit, ViewChild, HostListener, Directive, AfterViewInit } from '@angular/core';
import { MenuItems } from '../../shared/menu-items/menu-items';
import { AppHeaderComponent } from './header/header.component';
import { AppSidebarComponent } from './sidebar/sidebar.component';
import { Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';

/** @title Responsive sidenav */
@Component({
  selector: 'app-full-layout',
  templateUrl: 'full.component.html',
  styleUrls: [],
})
export class FullComponent implements OnInit, OnDestroy, AfterViewInit {
  mobileQuery: MediaQueryList;
  @ViewChild('snav') snav: any;
  toggleSidevar() {
    this.snav.toggle();

  }
  private _mobileQueryListener: () => void;
  // @Output() isValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() isValid = false;

 
  constructor(private router: Router,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher, public menuItems: MenuItems) {

    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    // this.isValid.emit(true);
  }
  ngOnInit(): void {
    var islogin = localStorage.getItem("IsLogin");
    debugger
    if (islogin == "true") {
      // var data = this.menuItems.getMenuitem().filter(r=>r.state==location.hash.split("/")[1]);
      // if((data!=null) && (localStorage.getItem("role")=="Admin")){
      //   this.isValid=true
      // }
      // else if((data!=null) && (localStorage.getItem("role")!="Admin")){

      this.isValid = true
      //}

    }
    else {
      this.isValid = false
      //this.router.navigate(["/login"]);
    }
  }


  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
  ngAfterViewInit() {

  }

}
